<!-- 
  面包屑导航
 -->

<template>
  <div>

      <div class="heasd">{{Otitle}}</div>
  </div>
</template>

<script>
export default {
    name:'Breadcrumb',
    props: {
      Otitle:String
    }
}
</script>

<style lang="less" scoped>
.heasd {
    height: 40px;
    border-bottom: 1px #ddd solid;
    text-align: left;
    line-height: 40px;
    padding: 0 0 0 15px;
    color: #2380d7;
    font-weight: bold;
  }
</style>