<!-- 人才政策 -->
<template>
  <div class="lists centebox">
    <Breadcrumb Otitle="政策申报" />
    <ul v-show="declarelist">
      <li v-for="(item, k) in declarelist" :key="k">
        <span>{{ item.createTime | formatDate }}</span>
        <router-link :to="'/declareitem/declarewz/' + item.id">{{
          item.title
        }}</router-link>
      </li>
    </ul>
    <div class="hr-10"></div>
    <el-pagination
      background
      @current-change="collChange"
      layout="prev, pager, next"
      :page-size="pageSize"
      :total="total"
      style="margin: 8px 0 0 0"
    ></el-pagination>
    <div class="hr-10"></div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/WebPanel/Breadcrumb.vue";

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      routelist: [],
      declarelist: "",
      pageSize: 10,
      total: 0,
    };
  },
  created() {
    this.routelist = this.$route.matched;
    this.Getdeclarelist(); //初始化数据
  },

  //用于存放所有的事件方法集合
  methods: {
    Getdeclarelist() {
      var self = this;
      this.$axios
        .get("/policy/page", {
          params: {
            current: 1,
            size: 10,
            status: 1,
          },
        })
        .then(function(res) {
          self.total = res.data.data.total;
          self.declarelist = res.data.data.records;
        })
        .catch(function(error) {
          console.error(error);
        });
    },

    //分页
    collChange(val) {
      var self = this;
      this.$axios
        .get("/policy/page", {
          params: {
            current: val,
            size: 10,
          },
        })
        .then(function(res) {
          self.total = res.data.data.total;
          self.declarelist = res.data.data.records;
        })
        .catch(function(error) {
          console.error(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.lists {
  background: #fff;
  width: 1156px;
  padding: 16px 24px;
  margin-bottom: 8px;
  height: 550px;
  ul {
    padding: 8px 20px;
    min-height: 400px;

    li {
      width: 100%;
      height: 40px;
      text-align: left;
      background: url("../../assets/diann.jpg") no-repeat left center;

      a {
        width: 70%;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        overflow: hidden;
        font-size: 16px;
        text-decoration: none;
        color: #333;
        line-height: 40px;
        display: block;
        //float: left;
        padding: 0 0 0 16px;
      }

      a:hover {
        color: #c00;
      }

      span {
        font-size: 16px;
        color: #888;
        float: right;
        line-height: 40px;
        display: block;
      }
    }
  }
}
@import "../../styles/common_responsive.less";
</style>
